<template>
  <div>
    <b-card no-body class="p-2">
      <b-row>
        <!-- teklif durum List -->
        <b-col cols="12" md="12" lg="12">
          <b-card>
            <b-card-title>{{ $t('Sipariş Geçmişi') }}</b-card-title>
            <b-table
              class="position-relative"
              :items="siparisGecmisList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              empty-text="Kayıt bulunamadı"
            >
              <template #cell(dtCreated)="data">
                {{ formatDateToDMYT(data.item.dtCreated) }}
              </template>
               <template #cell(siparisDurumu)="data">
                <b-badge :variant="data.item.siparisEnumVariant">{{ data.item.siparisEnum }}</b-badge>
              </template>
              <template #cell(islemYapanKullanici)="data">
                <div class="text-nowrap">
                  {{
                    data.item.islemYapanKullanici.sAd +
                    " " +
                    data.item.islemYapanKullanici.sSoyad
                  }}
                  <b-badge pill variant="light-primary" class="text-capitalize">
                    {{ data.item.islemYapanKullanici.kullaniciTipiLabel }}
                  </b-badge>
                </div>
              </template>
            </b-table>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { formatDateToDMYT } from '@core/utils/filter';
import StaticOptions from '@/common/options/StaticOptions'
import AlertService from '@/common/alert.service'

export default {
  props: {
    siparisData: {
      type: Object,
      default: () => { },
    },
  },
  data() {
    return {
      tableColumns: [
        { key: 'dtCreated', label: 'Tarih', sortable: true },
        { key: 'islemYapanKullanici', label: 'Durum Ekleyen' },
        { key: 'siparisDurumu', label: 'Durum' },
        { key: 'sAciklama', label: 'Açıklama' },
      ],
      siparisGecmisList: [],
    };
  },
  methods: {
    fetchSiparisGecmisList() {
      const query = { pSiparisId: this.siparisData.siparis.pId, siparisId: this.siparisData.siparis.id }
      this.$store.dispatch('siparisYonetim/fetchSiparisDurumGecmisis', query)
        .then(response => {
          if (response.statusCode === 200) {
            response.result.map(this.resolveSiparisGecmis);
            this.siparisGecmisList = response.result;
          } else {
            AlertService.error(this, response.message)
          }
        })
    },
    formatDateToDMYT,
    resolveKullaniciTipi(role) {
      const kullaniciTipi = StaticOptions.kullaniciTipiOptions.find(value => value.value === role)
      if (kullaniciTipi) {
        return kullaniciTipi.label
      }
      return ''
    },

    resolveSiparisGecmis(item) {
      const siparisEnum = StaticOptions.siparisDurumlari.find(value => value.value === item.siparisDurumu)
      if (!siparisEnum) {
        item.siparisEnum = ''
      }
      item.islemYapanKullanici.kullaniciTipiLabel = this.resolveKullaniciTipi(item.islemYapanKullanici.kullaniciTipi)
      item.siparisEnum = siparisEnum.label;
      item.siparisEnumVariant = siparisEnum.variant;
      return item;
    },
  },
  mounted() {
    this.fetchSiparisGecmisList();
  },
};
</script>
