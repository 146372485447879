<template>
  <b-card>
    <b-alert variant="danger" show>
      <div class="alert-body">
        {{ $t('Sipariş Durumu') }}
        <b-badge :variant="siparis.siparisDurumObject.variant">{{ siparis.siparisDurumObject.label }}</b-badge>
        {{ siparis.siparisDurumObject.desc }}
      </div>
    </b-alert>
   
    <b-button 
      variant="danger" 
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" 
      @click.prevent="siparisSil">
      <b-spinner
        v-if="$store.getters.getLoader"
        class="mr-1"
        small
        variant="light"
        />
      <feather-icon
        v-else
        icon="XIcon"
        class="mr-50"
        size="16" />
      {{ $t('Siparişi Sil') }}
    </b-button>
    <b-button 
      variant="success" 
      class="mb-1 mb-sm-0 mr-0 mr-sm-1 float-right" 
      @click.prevent="siparisYenileme">
      <b-spinner
        v-if="$store.getters.getLoader"
        class="mr-1"
        small
        variant="light"
        />
      <feather-icon
        v-else
        icon="XIcon"
        class="mr-50"
        size="16" />
      {{ $t('Siparişi Yenile') }}
    </b-button>
  </b-card>
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions';
import AlertService from '@/common/alert.service';

export default {
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      stepOptions: {
        0: false,
        1: false,
        2: false,
        3: false,
      },
      siparisOdemeSekilOptions: StaticOptions.siparisOdemeSekilOptions,
      siparis: this.siparisData.siparis,
      siparisCreateDb: this.siparisData.isCreatedbKullanici,

      // Odeme
      siparisOdeme: {
        odemeSekili: this.siparisData.siparis.odemeSekili,
      },

      // Onaylama
      modalObject: {
        showModal: false,
        musteri: {
          pId: this.siparisData.siparis.cariId,
        },
        onSuccess: this.onSuccessDbFirmaInfo,
      },
      siparisOnay: {
        dbFirmaInfoId: null,
        dbKullaniciTuruId: 1,
      },

      // Siparis Gereksinim
      siparisGerekliKurulum: {
        sAciklama: null,
      },
    };
  },
  methods: {
     onChangeStep(step) {
      if (this.siparis.siparisDurumu < step) {
        return;
      }
      this.siparis.siparisDurumObject = StaticOptions.siparisDurumlari.find(x => x.value === this.siparis.siparisDurumu)
      for (const key in this.stepOptions) {
        if (Object.hasOwnProperty.call(this.stepOptions, key)) {
          if (key === step) this.stepOptions[key] = !this.stepOptions[key];
          else this.stepOptions[key] = false;
        }
      }
    },
    siparisSil() {
      AlertService.confirmDelete(this, () => {
          this.$store.dispatch('siparisYonetim/siparisSil', this.siparis.id).then(response => {
            if (response.statusCode === 200) {
              AlertService.success(this, response.message)
              this.$router.back();
            } else {
              AlertService.error(this, response.message)
            }
          })
      }, 'Bu işlem siparişi her bağlantısı ile kaldıracaktır. Silmek istediğinizden emin misiniz?')
    },

    siparisYenileme() {
      AlertService.confirmSuccess(this, () => {
          this.$store.dispatch('siparisYonetim/siparisYenile', this.siparis.id).then(response => {
            if (response.statusCode === 200) {
              AlertService.success(this, response.message)
              this.$router.push({ name: 'manage-siparis-yenileme-detay', params: { id: response.result } })
            } else {
              AlertService.error(this, response.message)
            }
          })
      }, 'Bu işlem siparişine ait yeni bir sipariş kaydı oluşturacaktır. Db ve Kullanıcı lisansları güncellenecektır. Yenilemek istediğinizden emin misiniz?')
    },
  },
  mounted() {
    this.stepOptions[this.siparis.siparisDurumu] = true;
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped>
.custom-collapse:hover {
  cursor:pointer;
}
</style>
