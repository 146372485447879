<template>
  <div>
    <b-card no-body class="p-2">
      <b-form v-if="siparis">
        <b-row>
          <!-- Field: Müşteri Kodu -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Belge No')" label-for="belgeNo">
              <b-form-input
                id="belgeNo"
                v-model="siparis.belgeNo"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Hareket Kodu -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Hareket Kodu')" label-for="hareketKodu">
              <b-form-input
                id="hareketKodu"
                v-model="siparis.hareketKodu"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Vergi No -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Tarih')" label-for="belgeTarih">
              <b-form-input
                id="belgeTarih"
                v-model="siparis.belgeTarihFormatted"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Cari Kodu -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Cari Kodu')" label-for="cariKodu">
              <b-form-input
                id="cariKodu"
                v-model="siparis.cariKodu"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Cari Adı -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Cari Adı')" label-for="cariAdi">
              <b-form-input
                id="cariAdi"
                v-model="siparis.cariAdi"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: siparis Durumu -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Sipariş Durumu')" label-for="offerStatus">
              <b-form-input
                id="offerStatus"
                v-model="siparis.sSiparisDurumu"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Alış/Satış -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Alış/Satış')" label-for="alisSatisDurumu">
              <b-form-input
                id="alisSatisDurumu"
                v-model="siparis.alisSatisDurumu"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Satış Personel -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Satış Personel')" label-for="salesPersonName">
              <b-form-input
                id="salesPersonName"
                v-model="siparis.salesPersonName"
                readonly
              />
            </b-form-group>
          </b-col>

          <!-- Field: Kaynak -->
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Kaynak')" label-for="kaynak">
              <b-form-input
                id="kaynak"
                v-model="siparis.kaynak"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4">
            <b-form-group :label="$t('Mal Tutarı')" label-for="malTutari">
              <b-form-input
                id="malTutariFormatted"
                v-model="siparis.malTutariFormatted"
                readonly
              />
            </b-form-group>
          </b-col>
           <b-col cols="12" md="4">
            <b-form-group :label="$t('KDV Tutarı')" label-for="kdvTutari">
              <b-form-input
                id="kdvTutariFormatted"
                v-model="siparis.kdvTutariFormatted"
                readonly
              />
            </b-form-group>
          </b-col>
           <b-col cols="12" md="4">
            <b-form-group :label="$t('Genel Toplam')" label-for="genelToplam">
              <b-form-input
                id="genelToplamFormatted"
                v-model="siparis.genelToplamFormatted"
                readonly
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>

      <!-- Header: siparis Detayi -->
      <div class="d-flex mt-3 mb-2">
        <h4 class="mb-0 ml-50 text-primary">
          {{ $t('Sipariş Kalem Listesi') }}
        </h4>
      </div>

      <DxDataGrid
        :data-source="dataSource"
        :show-borders="true"
        :column-auto-width="true"
      >
        <DxColumn data-field="pId" caption="Id" />
        <DxColumn data-field="stokKodu" caption="Stok Kodu" />
        <DxColumn data-field="stockName" caption="Stok Adı" />
        <DxColumn data-field="birim" caption="Adet" />
        <DxColumn data-field="siparisMiktari" alignment="right" caption="Miktar" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="hareketBirimFiyat" alignment="right" caption="Birim fiyat" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="hareketTutari" alignment="right" caption="Hareket Tutarı" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="paraBirimi" caption="Para Birim" />
        <DxColumn data-field="kur" caption="Kur" />
        <DxColumn data-field="birimUcreti" alignment="right" caption="Birim Ücreti" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="yerelParaTutari" alignment="right" caption="YP.Tutarı" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="kdvYuzdesi" alignment="right" caption="Kdv Yüzdesi" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="iskontoluTutar" alignment="right" caption="İskontolu tutar" :customize-text="customizeDecimalDxGrid" />
        <DxColumn data-field="kdvTutari" alignment="right" caption="Kdv Tutarı" :customize-text="customizeDecimalDxGrid" />
        <DxSummary>
           <DxTotalItem
            column="siparisMiktari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="hareketTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="yerelParaTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="iskontoluTutar"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
          <DxTotalItem
            column="kdvTutari"
            summary-type="sum"
            display-format="{0}"
            :customize-text="customizeDecimalDxGrid"
          />
        </DxSummary>
      </DxDataGrid>
    </b-card>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSummary,
  DxTotalItem,
} from 'devextreme-vue/data-grid';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';

export default {
  props: {
    siparisData: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    DxDataGrid,
    DxColumn,
    DxSummary,
    DxTotalItem,
  },
  data() {
    return {
      dataSource: this.siparisData.siparis.siparisDetayList,
      siparis: this.siparisData.siparis,
      currentFilter: 'auto',
      dataGridRefName: 'dataGrid',
    };
  },
  methods: {
    customizeDecimalDxGrid,
  },
  created() {
    locale('tr')
  },
}
</script>

<style scoped>
#gridContainer {
  height: 440px;
}

.options {
  padding: 20px;
  margin-top: 20px;
  background-color: rgba(191, 191, 191, 0.15);
}

.caption {
  font-size: 18px;
  font-weight: 500;
}

.option {
  margin-top: 10px;
}

.option > span {
  margin-right: 10px;
}

.option > .dx-selectbox {
  display: inline-block;
  vertical-align: middle;
}
</style>
